import {
    Route,
    Routes,
    Navigate,
    BrowserRouter as Router
} from "react-router-dom";
import { FC } from "react";
import { Admin, Resource } from "react-admin";
import authProvider from "providers/auth.provider";
import dataProvider from "providers/data.provider";
import { AdminCreate, AdminsList } from "menu/admins";
import { ReactComponent as ShieldPerson } from "./icons/shield-person.svg";
import { ReactComponent as PersonIcon } from "./icons/person.svg";
import { ReactComponent as ItemsIcon } from "./icons/category.svg";
import { ReactComponent as TagIcon } from "./icons/tag.svg";
import { ReactComponent as SpotsIcon } from "./icons/location.svg";
import { ReactComponent as PickUpIcon } from "./icons/pick-up.svg";
import { ReactComponent as SellOffersIcon } from "./icons/shopping-cart-checkout.svg";
import { ReactComponent as UploadsIcon } from "./icons/gallery.svg";
import { ReactComponent as OtpsIcon } from "./icons/group-add.svg";
import { ReactComponent as BuyOffersIcon } from "./icons/add-shopping-cart.svg";
import { ReactComponent as OrdersIcon } from "./icons/shopping-cart.svg";
import { customHouseLightTheme } from "themes/houseLightTheme";
import { customHouseDarkTheme } from "themes/houseDarkTheme";
import Login from "routes/Login";
import ForgotPassword from "routes/ForgotPassword";
import ResetPassword from "routes/ResetPassword";
import { UserCreate, UserEdit, UsersList } from "menu/users";
import { TagCreate, TagEdit, TagsList } from "menu/tags";
import { ItemCreate, ItemEdit, ItemsList } from "menu/items";
import { SpotsList, SpotCreate, SpotEdit } from "menu/spots";
import { PickUpCreate, PickUpEdit, PickUpsList } from "menu/pick-ups";
import {
    SellOfferCreate,
    SellOfferEdit,
    SellOffersList
} from "menu/sell-offers";
import { OtpsList } from "menu/otps";
import { UploadsList } from "menu/uploads";
import { BuyOffersList } from "menu/buy-offers";
import { OrderEdit, OrdersList } from "menu/orders";

const AdminComponent: FC = () => {
    return (
        <Admin
            loginPage={Login}
            authProvider={authProvider}
            dataProvider={dataProvider}
            theme={customHouseLightTheme}
            darkTheme={customHouseDarkTheme}
            defaultTheme="dark"
        >
            <Resource
                name="orders"
                list={OrdersList}
                edit={OrderEdit}
                icon={OrdersIcon}
            />
            <Resource
                name="buy-offers"
                list={BuyOffersList}
                icon={BuyOffersIcon}
            />
            <Resource
                name="sell-offers"
                list={SellOffersList}
                create={SellOfferCreate}
                edit={SellOfferEdit}
                icon={SellOffersIcon}
            />
            <Resource
                name="items"
                list={ItemsList}
                create={ItemCreate}
                edit={ItemEdit}
                icon={ItemsIcon}
            />
            <Resource
                name="tags"
                list={TagsList}
                create={TagCreate}
                edit={TagEdit}
                icon={TagIcon}
            />
            <Resource
                name="pick-ups"
                list={PickUpsList}
                create={PickUpCreate}
                edit={PickUpEdit}
                icon={PickUpIcon}
            />
            <Resource
                name="spots"
                list={SpotsList}
                create={SpotCreate}
                edit={SpotEdit}
                icon={SpotsIcon}
            />

            <Resource
                name="users"
                list={UsersList}
                create={UserCreate}
                edit={UserEdit}
                icon={PersonIcon}
            />
            <Resource name="otps" list={OtpsList} icon={OtpsIcon} />
            <Resource
                name="admins"
                list={AdminsList}
                create={AdminCreate}
                icon={ShieldPerson}
            />
            <Resource name="uploads" list={UploadsList} icon={UploadsIcon} />
        </Admin>
    );
};

const App: FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/forgot-password" Component={ForgotPassword} />
                <Route
                    path="/reset-password/:token"
                    Component={ResetPassword}
                />
                <Route path="/*" Component={AdminComponent} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
};

export default App;
