import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";
import { ShowAboveClickableRow } from "components/show-above-clickable-row";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import {
    Datagrid,
    TextField,
    List,
    SearchInput,
    ReferenceField,
    FunctionField,
    SingleFieldList,
    ChipField,
    ReferenceArrayField
} from "react-admin";

const postFilters = [
    <SearchInput
        source="title"
        alwaysOn
        placeholder="Title contains"
        resettable
    />,
    <DynamicWidthAutocompleteReferenceInput
        optionText="name"
        reference="users"
        source="userId"
        alwaysOn
    />,
    <DynamicWidthAutocompleteReferenceInput
        optionText="title"
        reference="tags"
        source="tagId"
        alwaysOn
    />
];

export const ItemsList = (props: any) => (
    <List
        {...props}
        sort={{ field: undefined }}
        actions={<ListActionsToolbarWithoutFilters isCreateIncluded />}
        filters={postFilters}
        perPage={50}
    >
        <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButtons />}>
            <TextField source="title" label="Title" sortable={false} />
            <TextField
                source="description"
                label="Description"
                sortable={false}
            />
            <ReferenceField
                label="User"
                source="userId"
                reference="users"
                sortable={false}
            >
                <ShowAboveClickableRow>
                    <FunctionField
                        render={(record: any) => <span>{record.name}</span>}
                    />
                </ShowAboveClickableRow>
            </ReferenceField>
            <ReferenceArrayField
                source="tagIds"
                reference="tags"
                label="Tags"
                sortable={false}
            >
                <SingleFieldList>
                    <ChipField source="title" />
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    </List>
);
