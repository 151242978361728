import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import {
    Datagrid,
    TextField,
    List,
    SearchInput,
    ReferenceField,
    ImageField,
    BooleanField
} from "react-admin";

const postFilters = [
    <SearchInput
        source="name"
        alwaysOn
        placeholder="Name contains"
        resettable
    />,
    <SearchInput
        source="phone"
        alwaysOn
        placeholder="Phone contains"
        resettable
    />
];

export const UsersList = (props: any) => (
    <List
        {...props}
        sort={{ field: undefined }}
        actions={<ListActionsToolbarWithoutFilters isCreateIncluded />}
        filters={postFilters}
        perPage={50}
    >
        <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButtons />}>
            <TextField source="firstName" sortable={false} />
            <TextField source="lastName" sortable={false} />
            <TextField source="phone" label="Phone number" sortable={false} />
            <TextField
                source="paymentMethod"
                label="Payment Method"
                sortable={false}
            />
            <TextField
                source="paymentDestination"
                label="Payment recipient"
                sortable={false}
            />
            <BooleanField
                source="isPushNotificationsEnabled"
                label="Push notifications"
            />

            <BooleanField
                source="isSmsNotificationsEnabled"
                label="SMS notifications"
            />

            <ReferenceField
                source="avatarId"
                reference="uploads"
                label="Avatar"
            >
                <ImageField source="url" />
            </ReferenceField>
        </Datagrid>
    </List>
);
