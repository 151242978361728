import { DataProvider, withLifecycleCallbacks } from "react-admin";

import API from "api";

type UploadParam =
    | string
    | {
          id: string;
          title: string;
          src: string;
      }
    | {
          rawFile: File;
          title: string;
          src: string;
      };

const uploadFile = async (file: File) => {
    const { ok, data, error } = await API.Backend.Resource.create("uploads", {
        contentType: file.type,
        contentLength: file.size,
        title: file.name
    });

    if (!ok) {
        throw new Error(error);
    }

    await API.Backend.Upload.uploadFile(data.url, file, data.fields);

    return data.upload;
};

const dataProvider: DataProvider = {
    getList: async (resource, params) => {
        let {
            filter,
            pagination: { page, perPage },
            sort: { field, order }
        } = params;
        const { ok, data, pagination, error } =
            await API.Backend.Resource.getList(resource, {
                filter,
                order: field
                    ? {
                          [field]: order
                      }
                    : {},
                page: page.toString(),
                perPage: perPage.toString()
            });

        if (!ok) {
            throw new Error(error);
        }

        return {
            data,
            total: pagination.total,
            pageInfo: {
                hasNextPage: pagination.nextPage !== null,
                hasPrevPage: pagination.prevPage !== null
            }
        };
    },
    getOne: async (resource, params) => {
        const { ok, data, error } = await API.Backend.Resource.getOne(
            resource,
            params.id.toString()
        );

        if (!ok) {
            throw new Error(error);
        }

        return {
            data: data
        };
    },
    getMany: async (resource, params) => {
        const { ok, data, error } = await API.Backend.Resource.getMany(
            resource,
            params.ids.map((id) => id.toString())
        );
        if (!ok) {
            throw new Error(error);
        }

        return {
            data
        };
    },
    getManyReference: async (resource, params) => {
        const { ok, data, error } = await API.Backend.Resource.getManyReference(
            resource,
            params.target,
            params.id.toString(),
            {
                filter: params.filter,
                order: params.sort.field
                    ? {
                          [params.sort.field]: params.sort.order
                      }
                    : {}
            }
        );

        if (!ok) {
            throw new Error(error);
        }

        return {
            data,
            total: data.length,
            pageInfo: {
                hasNextPage: false,
                hasPrevPage: false
            }
        };
    },
    create: async (resource, params) => {
        const { ok, data, error } = await API.Backend.Resource.create(
            resource,
            params.data
        );

        if (!ok) {
            throw new Error(error);
        }

        return {
            data
        };
    },
    update: async (resource, params) => {
        const { id, ...paramsData } = params.data;
        const { ok, data, error } = await API.Backend.Resource.update(
            resource,
            params.id,
            paramsData
        );

        if (!ok) {
            throw new Error(error);
        }

        return {
            data
        };
    },
    updateMany: async (resource, params) => {
        const { ok, data, errors } = await API.Backend.Resource.updateMany(
            resource,
            params.ids.map((id) => id.toString()),
            params.data
        );

        if (!ok) {
            throw new Error(errors.join("\n"));
        }

        return {
            data
        };
    },
    delete: async (resource, params) => {
        const { ok, data, error } = await API.Backend.Resource.remove(
            resource,
            params.id.toString()
        );

        if (!ok) {
            throw new Error(error);
        }

        return {
            data
        };
    },
    deleteMany: async (resource, params) => {
        const { ok, data, errors } = await API.Backend.Resource.removeMany(
            resource,
            params.ids.map((id) => id.toString())
        );

        if (!ok) {
            throw new Error(errors.join("\n"));
        }

        return {
            data
        };
    }
};

const dataProviderWithLifecycleCallbacks = withLifecycleCallbacks(
    dataProvider,
    [
        {
            resource: "users",
            beforeSave: async (data: { avatarId?: UploadParam }) => {
                const dataWithUpload = { ...data };

                if (data.avatarId instanceof Object) {
                    if ("rawFile" in data.avatarId) {
                        const upload = await uploadFile(data.avatarId.rawFile);
                        dataWithUpload.avatarId = upload.id;
                    } else {
                        dataWithUpload.avatarId = data.avatarId.id;
                    }
                }

                return dataWithUpload;
            }
        },
        {
            resource: "tags",
            beforeSave: async (data: { iconId?: UploadParam }) => {
                const dataWithUpload = { ...data };

                if (data.iconId instanceof Object) {
                    if ("rawFile" in data.iconId) {
                        const upload = await uploadFile(data.iconId.rawFile);
                        dataWithUpload.iconId = upload.id;
                    } else {
                        dataWithUpload.iconId = data.iconId.id;
                    }
                }

                return dataWithUpload;
            }
        },
        {
            resource: "items",
            beforeSave: async (data: { photoIds?: UploadParam[] }) => {
                const dataWithUpload = { ...data };
                dataWithUpload.photoIds = [];
                for (const photo of data.photoIds ?? []) {
                    if (photo instanceof Object) {
                        if ("rawFile" in photo) {
                            const upload = await uploadFile(photo.rawFile);
                            dataWithUpload.photoIds.push(upload.id);
                        } else {
                            dataWithUpload.photoIds.push(photo.id);
                        }
                    } else {
                        dataWithUpload.photoIds.push(photo);
                    }
                }

                return dataWithUpload;
            }
        },
        {
            resource: "pick-ups",
            beforeSave: async (data: { photoIds?: UploadParam[] }) => {
                const dataWithUpload = { ...data };
                dataWithUpload.photoIds = [];
                for (const photo of data.photoIds ?? []) {
                    if (photo instanceof Object) {
                        if ("rawFile" in photo) {
                            const upload = await uploadFile(photo.rawFile);
                            dataWithUpload.photoIds.push(upload.id);
                        } else {
                            dataWithUpload.photoIds.push(photo.id);
                        }
                    } else {
                        dataWithUpload.photoIds.push(photo);
                    }
                }

                return dataWithUpload;
            }
        },
        {
            resource: "spots",
            beforeSave: async (data: { photoIds?: UploadParam[] }) => {
                const dataWithUpload = { ...data };
                dataWithUpload.photoIds = [];
                for (const photo of data.photoIds ?? []) {
                    if (photo instanceof Object) {
                        if ("rawFile" in photo) {
                            const upload = await uploadFile(photo.rawFile);
                            dataWithUpload.photoIds.push(upload.id);
                        } else {
                            dataWithUpload.photoIds.push(photo.id);
                        }
                    } else {
                        dataWithUpload.photoIds.push(photo);
                    }
                }

                return dataWithUpload;
            }
        }
    ]
);

export default dataProviderWithLifecycleCallbacks;
