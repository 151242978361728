import { BulkDeleteButton } from "react-admin";

const warning = "warning" as const;

export const BulkDeleteButtons = () => {
    return (
        <BulkDeleteButton
            size="medium"
            confirmTitle="Are you sure? This action is irreversible!"
            confirmContent=""
            confirmColor={warning}
            mutationMode="pessimistic"
        />
    );
};
