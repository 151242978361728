import { useEffect, useMemo, useRef, useState } from "react";
import {
    AutocompleteInput,
    AutocompleteInputProps,
    useChoicesContext,
    useRecordContext
} from "react-admin";
import PARSERS from "utils/parsers";

export interface DynamicWidthAutocompleteInputProps extends AutocompleteInputProps {
    optionText: string;
}

export const DynamicWidthAutocompleteInput = ({
    optionText,
    onChange,
    choices,
    ...props
}: DynamicWidthAutocompleteInputProps) => {
    const { allChoices } = useChoicesContext();

    const [optionTextWidth, setOptionTextWidth] = useState(200);
    const optionTextRef = useRef<HTMLSpanElement | null>(null);

    const longestOptionText = useMemo(
        () =>
            allChoices.reduce((longest, choice) => {
                const choiceText = PARSERS.getFieldValue(optionText, choice);
                return choiceText.length > longest.length
                    ? choiceText
                    : longest;
            }, ""),
        [allChoices]
    );

    useEffect(() => {
        if (optionTextRef.current) {
            const optionTextWidth = optionTextRef.current.clientWidth;
            setOptionTextWidth(optionTextWidth);
        }
    }, [longestOptionText]);

    return (
        <>
            <span
                style={{
                    height: "0px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    position: "absolute"
                }}
                ref={optionTextRef}
            >
                {longestOptionText}
            </span>
            <AutocompleteInput
                style={{
                    width: optionTextWidth + (props.readOnly ? 60 : 85),
                    minWidth: "200px",
                    maxWidth: "100%"
                }}
                optionText={optionText}
                filterToQuery={(option) => ({ [optionText]: option })}
                {...props}
            />
        </>
    );
};
