import { deepmerge } from "@mui/utils";
import { houseLightTheme } from "react-admin";
import {
    commonInputMultilineStyles,
    commonMuiAutocompleteStyles,
    commonMuiMenuItemStyles,
    commonMuiStackDropZoneStyles,
    commonMuiStackInputStyles
} from "./commonThemeStyles";

export const customHouseLightTheme = deepmerge(houseLightTheme, {
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    ...commonMuiMenuItemStyles
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    ...commonInputMultilineStyles
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    ...commonMuiAutocompleteStyles
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    "& .RaFileInput-dropZone": {
                        ...commonMuiStackDropZoneStyles
                    },
                    ...commonMuiStackInputStyles
                }
            }
        }
    }
});
