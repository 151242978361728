export const uploadFile = async (
    url: string,
    file: File,
    fields: Record<string, string>
) => {
    const formData = new FormData();

    Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
    });

    formData.append("file", file);

    await fetch(url, {
        method: "POST",
        body: formData,
        mode: "no-cors"
    });

    return;
};
