import { Login as BaseLogin, LoginForm } from "react-admin";

const Login = () => {
    return (
        <BaseLogin
            children={
                <div>
                    <LoginForm></LoginForm>
                    <a
                        style={{
                            display: "block",
                            textAlign: "center",
                            marginBottom: "0.5rem",
                            marginTop: "-1rem"
                        }}
                        href="/forgot-password"
                    >
                        Forgot Password
                    </a>
                </div>
            }
        />
    );
};

export default Login;
