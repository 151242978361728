export const commonMuiMenuItemStyles = {
    "&.MuiMenuItem-root": {
        minWidth: "43px"
    }
};
export const commonInputMultilineStyles = {
    "& .MuiInputBase-inputMultiline": {
        padding: "0.5px 4px"
    }
};

export const commonMuiAutocompleteStyles = {
    "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
        padding: "0px 0px 0px 9px"
    },
    ".RaList-actions & .MuiInputLabel-root": {
        top: "0px"
    },
    "& .MuiInputLabel-root": {
        top: "-6px"
    }
};

export const commonMuiStackInputStyles = {
    "&.ra-input": {
        maxWidth: "fit-content",
        minWidth: "400px"
    }
};
export const commonMuiStackDropZoneStyles = {
    padding: "9px 20px !important"
};
