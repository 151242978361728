import * as Auth from "./entities/auth";
import * as Resource from "./entities/resource";
import * as Upload from ".//entities/upload";

const Backend = {
    Auth,
    Resource,
    Upload
};

export default Backend;
