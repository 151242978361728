import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";
import { ShowAboveClickableRow } from "components/show-above-clickable-row";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import {
    Datagrid,
    TextField,
    List,
    SearchInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceField,
    FunctionField,
    DateField
} from "react-admin";

const postFilters = [
    <SearchInput
        source="name"
        alwaysOn
        placeholder="Name keywords"
        resettable
    />,
    <SearchInput
        source="description"
        alwaysOn
        placeholder="Description contains"
        resettable
    />,
    <DynamicWidthAutocompleteReferenceInput
        optionText="name"
        reference="spots"
        source="spotId"
        alwaysOn
    />
];

export const PickUpsList = (props: any) => (
    <List
        {...props}
        sort={{ field: undefined }}
        actions={<ListActionsToolbarWithoutFilters isCreateIncluded />}
        filters={postFilters}
        perPage={50}
    >
        <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButtons />}>
            <TextField source="name" label="Name" sortable={false} />
            <ReferenceField
                label="Spot"
                source="spotId"
                reference="spots"
                sortable={false}
            >
                <ShowAboveClickableRow>
                    <FunctionField
                        render={(record: any) => <span>{record.name}</span>}
                    />
                </ShowAboveClickableRow>
            </ReferenceField>
            <TextField
                source="description"
                label="Description"
                sortable={false}
            />
            <DateField source="start" label="Start" sortable={false} />
            <DateField source="end" label="End" sortable={false} />
        </Datagrid>
    </List>
);
