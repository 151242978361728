import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import {
    Datagrid,
    List,
    ImageField,
    ReferenceArrayField,
    ReferenceField,
    TextField,
    SingleFieldList,
    ChipField,
    WrapperField,
    BooleanInput
} from "react-admin";

const postFilters = [
    <DynamicWidthAutocompleteReferenceInput
        optionText="name"
        reference="users"
        source="ownerId"
        alwaysOn
    />,
    <BooleanInput source="excludeUsed" alwaysOn />
];

export const UploadsList = (props: any) => (
    <List
        {...props}
        sort={{ field: undefined }}
        actions={<ListActionsToolbarWithoutFilters />}
        filters={postFilters}
        perPage={50}
    >
        <Datagrid bulkActionButtons={<BulkDeleteButtons />}>
            <ImageField source="url" />
            <ReferenceField source="ownerId" reference="users">
                <TextField source="name" />
            </ReferenceField>
            <WrapperField label="Used By">
                <ReferenceArrayField source="relatedTagIds" reference="tags">
                    <SingleFieldList>
                        <ChipField source="title" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField source="relatedItemIds" reference="items">
                    <SingleFieldList>
                        <ChipField source="title" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField source="relatedSpotIds" reference="spots">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField source="relatedUserIds" reference="users">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <ReferenceArrayField
                    source="relatedPickUpIds"
                    reference="pick-ups"
                >
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </WrapperField>
        </Datagrid>
    </List>
);
