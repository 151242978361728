import {
    Identifier,
    ImageField,
    ImageInput,
    Loading,
    RaRecord,
    useGetMany,
    useRecordContext,
    useReference
} from "react-admin";
import { useFormContext } from "react-hook-form";

const MAX_UPLOAD_SIZE = 5 * 10 ** 6;

const maxFileSize = (max: number) => {
    return (value: any) => {
        const sizeIsLarge = (val: any) => val?.rawFile?.size > max;
        return (
            Array.isArray(value) ? value.some(sizeIsLarge) : sizeIsLarge(value)
        )
            ? "Files larger than 5BM is not allowed"
            : undefined;
    };
};

const UploadArrayInputWithRecord = ({
    source,
    record
}: {
    record: RaRecord<Identifier>;
    source: string;
}) => {
    const {
        data: uploads,
        isFetching,
        error
    } = useGetMany(
        "uploads",
        {
            ids: record[source]
        },
        {}
    );

    const { setValue, getValues } = useFormContext();

    if (isFetching) {
        return <Loading />;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    if (uploads) {
        const values = getValues();
        const value = values[source];
        if (
            Array.isArray(value) &&
            value.some((upload) => typeof upload === "string")
        ) {
            setValue(
                source,
                value.map((id) => {
                    const upload = uploads.find((upload) => upload.id === id);
                    return { title: upload.id, src: upload.url, id: upload.id };
                })
            );
        }
    }

    return (
        <ImageInput
            source={source}
            validate={maxFileSize(MAX_UPLOAD_SIZE)}
            multiple
        >
            <ImageField source="src" />
        </ImageInput>
    );
};

const UploadSingleInputWithRecord = ({
    source,
    record
}: {
    source: string;
    record: RaRecord<Identifier>;
}) => {
    const {
        referenceRecord: upload,
        isFetching,
        error
    } = useReference<{ id: string; url: string }>({
        reference: "uploads",
        id: record[source]
    });

    const { setValue, getValues } = useFormContext();

    if (isFetching) {
        return <Loading />;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    if (upload) {
        const values = getValues();
        const value = values[source];
        if (typeof value === "string") {
            setValue(source, { title: upload.id, src: upload.url });
        }
    }

    return (
        <ImageInput source={source} validate={maxFileSize(MAX_UPLOAD_SIZE)}>
            <ImageField source="src" />
        </ImageInput>
    );
};

const UploadInputWithoutRecord = ({
    source,
    multiple = false
}: {
    source: string;
    multiple?: boolean;
}) => {
    return (
        <ImageInput
            source={source}
            multiple={multiple}
            validate={maxFileSize(MAX_UPLOAD_SIZE)}
        >
            <ImageField source="src" />
        </ImageInput>
    );
};

export const UploadInput = ({
    source,
    multiple = false
}: {
    source: string;
    multiple?: boolean;
}) => {
    const record = useRecordContext();

    if (record) {
        if (multiple) {
            return (
                <UploadArrayInputWithRecord source={source} record={record} />
            );
        } else {
            return (
                <UploadSingleInputWithRecord source={source} record={record} />
            );
        }
    } else {
        return <UploadInputWithoutRecord source={source} multiple={multiple} />;
    }
};
