import {
    Create,
    ImageField,
    ImageInput,
    SimpleForm,
    required
} from "react-admin";
import Typography from "@mui/material/Typography";
import { DefaultTextInput } from "components/default-text-input";
import { UploadInput } from "components/upload/UploadInput";

export const TagCreate = () => {
    return (
        <Create redirect="list">
            <SimpleForm>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Create tag
                </Typography>

                <DefaultTextInput
                    source="title"
                    type="text"
                    validate={[required()]}
                />

                <DefaultTextInput
                    source="color"
                    type="text"
                    validate={[required()]}
                />

                <UploadInput source="iconId" />
            </SimpleForm>
        </Create>
    );
};
