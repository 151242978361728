import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import { Datagrid, EmailField, List, SearchInput } from "react-admin";

const postFilters = [<SearchInput source="email" alwaysOn />];

export const AdminsList = (props: any) => (
    <List
        {...props}
        sort={{ field: undefined }}
        actions={<ListActionsToolbarWithoutFilters isCreateIncluded />}
        filters={postFilters}
        perPage={50}
    >
        <Datagrid>
            <EmailField source="email" />
        </Datagrid>
    </List>
);
