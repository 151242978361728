import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";
import { ShowAboveClickableRow } from "components/show-above-clickable-row";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import { unitTypes } from "constants/unit-types";
import {
    Datagrid,
    TextField,
    List,
    ReferenceField,
    FunctionField,
    SingleFieldList,
    ChipField,
    ReferenceArrayField,
    BooleanField,
    DateField,
    SelectField,
    NumberField,
    NullableBooleanInput,
    CloneButton,
    WithRecord,
    CreateButton,
    useRecordContext
} from "react-admin";

const postFilters = [
    <DynamicWidthAutocompleteReferenceInput
        optionText="name"
        reference="users"
        source="userId"
        alwaysOn
    />,
    <DynamicWidthAutocompleteReferenceInput
        optionText="name"
        reference="users"
        source="collaboratorId"
        alwaysOn
    />,
    <DynamicWidthAutocompleteReferenceInput
        optionText="title"
        reference="items"
        source="itemId"
        alwaysOn
    />,
    <DynamicWidthAutocompleteReferenceInput
        optionText="name"
        reference="pick-ups"
        source="pickUpId"
        alwaysOn
    />,
    <NullableBooleanInput source="isActive" alwaysOn />
];

export const SellOffersList = (props: any) => (
    <List
        {...props}
        sort={{ field: undefined }}
        actions={<ListActionsToolbarWithoutFilters isCreateIncluded />}
        filters={postFilters}
        perPage={50}
    >
        <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButtons />}>
            <ReferenceField
                label="User"
                source="userId"
                reference="users"
                sortable={false}
            >
                <ShowAboveClickableRow>
                    <FunctionField
                        render={(record: any) => <span>{record.name}</span>}
                    />
                </ShowAboveClickableRow>
            </ReferenceField>
            <ReferenceArrayField
                source="collaboratorIds"
                reference="users"
                label="Collaborators"
                sortable={false}
            >
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>

            <ReferenceField
                label="Item"
                source="itemId"
                reference="items"
                sortable={false}
            >
                <ShowAboveClickableRow>
                    <FunctionField
                        render={(record: any) => <span>{record.title}</span>}
                    />
                </ShowAboveClickableRow>
            </ReferenceField>

            <SelectField
                source="unitType"
                label="Unit Type"
                choices={unitTypes}
                sortable={false}
            />

            <TextField
                source="unitDescription"
                label="Unit Description"
                sortable={false}
            />

            <TextField source="unitPrice" label="Unit Price" sortable={false} />

            <ReferenceField
                label="PickUp"
                source="pickUpId"
                reference="pick-ups"
                sortable={false}
            >
                <ShowAboveClickableRow>
                    <FunctionField
                        render={(record: any) => <span>{record.name}</span>}
                    />
                </ShowAboveClickableRow>
            </ReferenceField>

            <NumberField
                source="availability"
                label="Availability"
                sortable={false}
            />

            <NumberField source="priority" label="Priority" sortable={false} />

            <NumberField source="ordered" label="Ordered" sortable={false} />

            <DateField source="deadline" label="Deadline" sortable={false} />

            <BooleanField
                source="isActive"
                label="Is Active"
                sortable={false}
            />

            <WithRecord
                render={({
                    id,
                    pickUpId,
                    availability,
                    deadline,
                    priority,
                    ...record
                }) => <CloneButton record={record} label="" />}
            />
        </Datagrid>
    </List>
);
