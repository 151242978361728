import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import API from "api/backend";

const ResetPassword = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    const passwordRef = useRef<any>();
    const repeatPasswordRef = useRef<any>();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState<string>();

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const password = passwordRef.current.value;
        const repeatPassword = repeatPasswordRef.current.value;

        if (password !== repeatPassword) {
            window.alert("Password don't match");
            return;
        }

        await API.Auth.resetPassword({
            password,
            token: token!
        });

        const { ok } = await API.Auth.signIn({
            email: email!,
            password
        });

        if (ok) navigate("/", { replace: true });
    };

    useEffect(() => {
        (async () => {
            if (token) {
                setIsLoading(true);
                try {
                    const { ok, data } =
                        await API.Auth.checkPasswordToken(token);

                    if (ok) {
                        setEmail(data.email);
                    } else {
                        navigate("/", { replace: true });
                    }
                } catch (err: any) {
                    console.log(err);
                    navigate("/", { replace: true });
                } finally {
                    setIsLoading(false);
                }
            } else navigate("/", { replace: true });
        })();
    }, []);

    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            {isLoading || !email ? (
                <div>Loading...</div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div style={{ fontSize: "24px" }}>{email}</div>
                    <input
                        type="password"
                        ref={passwordRef}
                        style={{
                            padding: "0.5rem"
                        }}
                        autoComplete="off"
                        placeholder="Password"
                        required
                    />
                    <input
                        type="password"
                        ref={repeatPasswordRef}
                        style={{
                            padding: "0.5rem"
                        }}
                        autoComplete="off"
                        placeholder="Repeat password"
                        required
                    />
                    <button
                        type="submit"
                        style={{
                            padding: "0.5rem",
                            width: "100px"
                        }}
                    >
                        Submit
                    </button>
                </form>
            )}
        </div>
    );
};

export default ResetPassword;
