import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import {
    Datagrid,
    TextField,
    List,
    SearchInput,
    NumberField
} from "react-admin";

const postFilters = [
    <SearchInput
        source="title"
        alwaysOn
        placeholder="Title contains"
        resettable
    />
];

export const OtpsList = (props: any) => (
    <List
        {...props}
        sort={{ field: undefined }}
        actions={<ListActionsToolbarWithoutFilters isCreateIncluded />}
        filters={postFilters}
        perPage={50}
    >
        <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButtons />}>
            <TextField source="phone" label="Phone" sortable={false} />
            <TextField source="type" label="Type" sortable={false} />
            <NumberField source="code" label="Code" sortable={false} />
        </Datagrid>
    </List>
);
