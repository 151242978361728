import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import API from "api/backend";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const emailRef = useRef<any>();

    const handleSubmit = async () => {
        const email = emailRef.current.value;

        const { ok } = await API.Auth.forgotPassword({ email });

        if (ok) navigate("/", { replace: true });
    };

    return (
        <form
            style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <input
                type="email"
                ref={emailRef}
                style={{
                    padding: "0.5rem"
                }}
                autoComplete="off"
                placeholder="Enter email"
                required
            />
            <button
                style={{
                    padding: "0.5rem",
                    width: "100px"
                }}
                onClick={handleSubmit}
            >
                Send
            </button>
        </form>
    );
};

export default ForgotPassword;
