import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import {
    Datagrid,
    List,
    ReferenceField,
    ChipField,
    NumberField
} from "react-admin";

const postFilters = [
    <DynamicWidthAutocompleteReferenceInput
        optionText="name"
        reference="users"
        source="buyerId"
        alwaysOn
    />,
    <DynamicWidthAutocompleteReferenceInput
        optionText="name"
        reference="users"
        source="sellerId"
        alwaysOn
    />
];

export const BuyOffersList = (props: any) => (
    <List
        {...props}
        sort={{ field: undefined }}
        actions={<ListActionsToolbarWithoutFilters />}
        filters={postFilters}
        perPage={50}
    >
        <Datagrid bulkActionButtons={false}>
            <ReferenceField
                label="Buyer"
                source="orderId"
                reference="orders"
                sortable={false}
            >
                <ReferenceField
                    source="buyerId"
                    reference="users"
                    sortable={false}
                >
                    <ChipField source="name" />
                </ReferenceField>
            </ReferenceField>

            <ReferenceField
                label="Seller"
                source="orderId"
                reference="orders"
                sortable={false}
            >
                <ReferenceField
                    source="sellerId"
                    reference="users"
                    sortable={false}
                >
                    <ChipField source="name" />
                </ReferenceField>
            </ReferenceField>

            <ReferenceField
                label="Item"
                source="sellOfferId"
                reference="sell-offers"
                sortable={false}
            >
                <ReferenceField
                    label="Item"
                    source="itemId"
                    reference="items"
                    sortable={false}
                >
                    <ChipField source="title" />
                </ReferenceField>
            </ReferenceField>

            <ReferenceField
                label="PickUp"
                source="sellOfferId"
                reference="sell-offers"
                sortable={false}
            >
                <ReferenceField
                    label="PickUp"
                    source="pickUpId"
                    reference="pick-ups"
                    sortable={false}
                >
                    <ChipField source="name" />
                </ReferenceField>
            </ReferenceField>

            <ReferenceField
                label="Unit Type"
                source="sellOfferId"
                reference="sell-offers"
                sortable={false}
                link={false}
            >
                <NumberField source="unitType" />
            </ReferenceField>

            <ReferenceField
                label="Unit Price"
                source="sellOfferId"
                reference="sell-offers"
                sortable={false}
                link={false}
            >
                <NumberField source="unitPrice" />
            </ReferenceField>

            <NumberField source="quantity" label="Quantity" sortable={false} />
            <NumberField source="total" label="Total" sortable={false} />
        </Datagrid>
    </List>
);
