import { BulkDeleteButtons } from "components/bulk-action-buttons/BulkDeleteButtons";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import {
    Datagrid,
    TextField,
    List,
    SearchInput,
    ReferenceField,
    ImageField
} from "react-admin";

const postFilters = [
    <SearchInput
        source="title"
        alwaysOn
        placeholder="Title contains"
        resettable
    />
];

export const TagsList = (props: any) => (
    <List
        {...props}
        sort={{ field: undefined }}
        actions={<ListActionsToolbarWithoutFilters isCreateIncluded />}
        filters={postFilters}
        perPage={50}
    >
        <Datagrid rowClick="edit" bulkActionButtons={<BulkDeleteButtons />}>
            <TextField source="title" label="Title" sortable={false} />
            <TextField source="color" label="Color" sortable={false} />
            <ReferenceField source="iconId" reference="uploads" label="Icon">
                <ImageField source="url" />
            </ReferenceField>
        </Datagrid>
    </List>
);
