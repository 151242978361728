export const unitTypes = [
    {
        id: "pack",
        name: "Pack"
    },
    {
        id: "jar",
        name: "Jar"
    },
    {
        id: "bottle",
        name: "Bottle"
    },
    {
        id: "item",
        name: "Item"
    },
    {
        id: "kg",
        name: "Kg"
    },
    {
        id: "volume",
        name: "Volume"
    },
    {
        id: "serving",
        name: "Serving"
    }
];
