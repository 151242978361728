export const paymentStatuses = [
    {
        id: "pending",
        name: "Pending"
    },
    {
        id: "paid",
        name: "Paid"
    },
    {
        id: "received",
        name: "Received"
    },
    {
        id: "allocated",
        name: "Allocated"
    },
    {
        id: "refund",
        name: "Refund"
    }
];
