import { AuthProvider } from "react-admin";

import API from "api";

const authProvider: AuthProvider = {
    getPermissions: async () => {
        return;
    },
    checkAuth: async () => {
        const value = await API.Backend.Auth.getMe();

        if (!value.data.auth) {
            throw new Error();
        }
    },
    login: async ({ username, password }) => {
        const { ok, error } = await API.Backend.Auth.signIn({
            email: username,
            password
        });

        if (!ok) {
            throw new Error(error);
        }
    },
    logout: async () => {
        const response: any = await API.Backend.Auth.signOut();

        if (!response.ok) {
            throw new Error(response.error);
        }
    },
    checkError: (error) => {
        if (!error) {
            return Promise.resolve();
        }

        const { status } = error;

        if (status === 401 || status === 403) {
            return Promise.reject();
        }

        return Promise.resolve();
    }
};

export default authProvider;
