export const orderStatuses = [
    {
        id: "pending",
        name: "Pending"
    },
    {
        id: "approved",
        name: "Approved"
    },
    {
        id: "canceled",
        name: "Canceled"
    },
    {
        id: "picked-up",
        name: "Picked Up"
    }
];
