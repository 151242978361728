import { BooleanInput, Edit, SimpleForm, required } from "react-admin";
import { PageTitle } from "components/navbar/PageTitle";
import { EditToolbar } from "components/toolbar/EditToolbar";
import { DefaultTextInput } from "components/default-text-input";
import { UploadInput } from "components/upload/UploadInput";

export const UserEdit = () => {
    return (
        <Edit
            title={
                <PageTitle
                    generatePageTitle={(record: any) => `${record?.key}`}
                />
            }
        >
            <SimpleForm toolbar={<EditToolbar />}>
                <DefaultTextInput
                    source="phone"
                    type="phone"
                    label="Phone number"
                    validate={[required()]}
                />

                <DefaultTextInput
                    source="firstName"
                    type="text"
                    validate={[required()]}
                />

                <DefaultTextInput
                    source="lastName"
                    type="text"
                    validate={[required()]}
                />

                <DefaultTextInput source="paymentMethod" type="text" />

                <DefaultTextInput
                    source="paymentDestination"
                    type="text"
                    label="Payment recipient"
                />

                <DefaultTextInput source="about" type="text" multiline />

                <BooleanInput
                    source="isPushNotificationsEnabled"
                    label="Push notifications"
                />

                <BooleanInput
                    source="isSmsNotificationsEnabled"
                    label="SMS notifications"
                />

                <UploadInput source="avatarId" />
            </SimpleForm>
        </Edit>
    );
};
