import {
    BooleanInput,
    Datagrid,
    DateTimeInput,
    Edit,
    NumberInput,
    ReferenceArrayField,
    SelectInput,
    SimpleForm,
    TextField,
    ReferenceField,
    required,
    NumberField,
    DateField
} from "react-admin";
import { PageTitle } from "components/navbar/PageTitle";
import { EditToolbar } from "components/toolbar/EditToolbar";
import { DefaultTextInput } from "components/default-text-input";
import { unitTypes } from "constants/unit-types";
import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";
import { orderStatuses } from "constants/order-statuses";
import { paymentStatuses } from "constants/payment-statuses";
import { ShowAboveClickableRow } from "components/show-above-clickable-row";
import { cursorDefaultStyles } from "utils/RowClickFalseStyles";

export const OrderEdit = () => {
    return (
        <Edit
            title={
                <PageTitle
                    generatePageTitle={(record: any) => `${record?.key}`}
                />
            }
        >
            <SimpleForm toolbar={<EditToolbar />}>
                <DynamicWidthAutocompleteReferenceInput
                    optionText="name"
                    reference="users"
                    source="sellerId"
                    readOnly
                />

                <DynamicWidthAutocompleteReferenceInput
                    optionText="name"
                    reference="users"
                    source="buyerId"
                    readOnly
                />

                <DynamicWidthAutocompleteReferenceInput
                    optionText="name"
                    reference="pick-ups"
                    source="pickUpId"
                    readOnly
                />

                <SelectInput
                    source="status"
                    choices={orderStatuses}
                    optionValue="id"
                />
                <SelectInput
                    source="paymentStatus"
                    choices={paymentStatuses}
                    optionValue="id"
                />

                <NumberInput label="Payment Amount" source="total" readOnly />

                <ReferenceArrayField
                    reference="buy-offers"
                    source="buyOfferIds"
                    sort={{ field: "", order: "DESC" }}
                    sx={{ width: "50%", minWidth: 600 }}
                >
                    <Datagrid
                        rowClick="false"
                        sx={cursorDefaultStyles}
                        bulkActionButtons={false}
                    >
                        <ReferenceField
                            label="Item"
                            source="sellOfferId"
                            reference="sell-offers"
                        >
                            <ReferenceField source="itemId" reference="items">
                                <TextField source="title" />
                            </ReferenceField>
                        </ReferenceField>

                        <ReferenceField
                            label="Unit Type"
                            source="sellOfferId"
                            reference="sell-offers"
                            link={false}
                        >
                            <TextField source="unitType" />
                        </ReferenceField>

                        <ReferenceField
                            label="Unit Price"
                            source="sellOfferId"
                            reference="sell-offers"
                            link={false}
                        >
                            <TextField source="unitPrice" />
                        </ReferenceField>

                        <NumberField source="quantity" />
                        <NumberField source="total" />
                    </Datagrid>
                </ReferenceArrayField>

                <DateTimeInput
                    label="Date Created"
                    source="createdAt"
                    readOnly
                />

                <DateTimeInput label="Date Paid" source="paidAt" readOnly />

                <DateTimeInput
                    label="Date Approved"
                    source="approvedAt"
                    readOnly
                />

                <DateTimeInput
                    label="Date Concluded"
                    source="concludedAt"
                    readOnly
                />
            </SimpleForm>
        </Edit>
    );
};
