import {
    AutocompleteArrayInput,
    AutocompleteInput,
    Create,
    DateTimeInput,
    ImageField,
    ImageInput,
    ReferenceArrayInput,
    ReferenceInput,
    SimpleForm,
    required
} from "react-admin";
import Typography from "@mui/material/Typography";
import { DefaultTextInput } from "components/default-text-input";
import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";
import { UploadInput } from "components/upload/UploadInput";

export const PickUpCreate = () => {
    return (
        <Create redirect="list">
            <SimpleForm>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Create pick-up
                </Typography>

                <DefaultTextInput
                    source="description"
                    type="text"
                    multiline
                    validate={[required()]}
                />

                <DynamicWidthAutocompleteReferenceInput
                    validate={[required()]}
                    optionText="name"
                    reference="spots"
                    source="spotId"
                />

                <DateTimeInput source="start" validate={[required()]} />

                <DateTimeInput source="end" validate={[required()]} />

                <UploadInput multiple source="photoIds" />
            </SimpleForm>
        </Create>
    );
};
