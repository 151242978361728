import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    Create,
    DateTimeInput,
    NumberInput,
    ReferenceArrayInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    required
} from "react-admin";
import Typography from "@mui/material/Typography";
import { DefaultTextInput } from "components/default-text-input";
import { unitTypes } from "constants/unit-types";
import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";

export const SellOfferCreate = () => {
    return (
        <Create redirect="list">
            <SimpleForm>
                <Typography variant="h6" sx={{ marginBottom: 1 }}>
                    Create item
                </Typography>

                <DynamicWidthAutocompleteReferenceInput
                    validate={[required()]}
                    optionText="name"
                    reference="users"
                    source="userId"
                />

                <ReferenceArrayInput
                    source="collaboratorIds"
                    reference="users"
                    alwaysOn
                >
                    <AutocompleteArrayInput
                        filterToQuery={(user) => ({ name: user })}
                        optionText="name"
                        label="Collaborators"
                    />
                </ReferenceArrayInput>

                <DynamicWidthAutocompleteReferenceInput
                    validate={[required()]}
                    optionText="title"
                    reference="items"
                    source="itemId"
                />

                <SelectInput
                    source="unitType"
                    choices={unitTypes}
                    optionValue="id"
                    validate={[required()]}
                />

                <DefaultTextInput
                    source="unitDescription"
                    multiline
                    validate={[required()]}
                />

                <NumberInput source="unitPrice" validate={[required()]} />

                <DynamicWidthAutocompleteReferenceInput
                    validate={[required()]}
                    optionText="name"
                    reference="pick-ups"
                    source="pickUpId"
                />

                <NumberInput source="availability" validate={[required()]} />
                <NumberInput source="priority" />

                <DateTimeInput source="deadline" validate={[required()]} />
                <BooleanInput source="isActive" validate={[required()]} />
            </SimpleForm>
        </Create>
    );
};
