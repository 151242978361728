import { deepmerge } from "@mui/utils";
import { houseDarkTheme } from "react-admin";
import {
    commonInputMultilineStyles,
    commonMuiAutocompleteStyles,
    commonMuiMenuItemStyles,
    commonMuiStackDropZoneStyles,
    commonMuiStackInputStyles
} from "./commonThemeStyles";

export const customHouseDarkTheme = deepmerge(houseDarkTheme, {
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    "&.RaMenuItemLink-active .MuiListItemIcon-root": {
                        color: "#FFADDE"
                    },
                    "&.RaMenuItemLink-active": {
                        color: "#FFADDE !important"
                    },
                    ...commonMuiMenuItemStyles
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    "&.MuiButton-text.MuiButton-colorPrimary": {
                        color: "#FFADDE"
                    },
                    "&.MuiButton-containedPrimary": {
                        backgroundColor: "#FFADDE"
                    },
                    "&.MuiButton-containedPrimary:hover": {
                        backgroundColor: "#E79ECA"
                    },
                    "&.MuiButton-colorError": {
                        color: "#FFE481"
                    },
                    "&.MuiSwitch-colorPrimary.Mui-checked": {
                        color: "#FFADDE"
                    },
                    "&.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                        backgroundColor: "#FFADDE"
                    },
                    "&.MuiCheckbox-root.Mui-checked": {
                        color: "#FFADDE"
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    "&.Mui-error": {
                        color: "#FFE481"
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.Mui-error": {
                        color: "#FFE481"
                    },
                    "&.Mui-focused": {
                        color: "#FFADDE"
                    }
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    "&.RaReferenceField-link>*": {
                        color: "#FFADDE"
                    },
                    "&.MuiLink-root": {
                        color: "#FFADDE"
                    }
                }
            }
        },
        RaBulkActionsToolbar: {
            styleOverrides: {
                root: {
                    "& .RaBulkActionsToolbar-toolbar": {
                        backgroundColor: "#FFADDE"
                    },
                    "& .RaBulkActionsToolbar-toolbar .MuiButton-text.MuiButton-colorPrimary":
                        {
                            color: "white !important"
                        }
                }
            }
        },
        RaReferenceField: {
            styleOverrides: {
                root: {
                    "& .RaReferenceField-link": {
                        color: "#212121 !important"
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FFADDE !important"
                    },
                    "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FFE481 !important"
                    },
                    ...commonInputMultilineStyles
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    ...commonMuiAutocompleteStyles
                }
            }
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    "& .MuiChip-root": {
                        color: "#FFADDE !important"
                    },
                    "& .RaFileInput-dropZone": {
                        background: "#FFADDE !important",
                        color: "#212121 !important",
                        ...commonMuiStackDropZoneStyles
                    },
                    ...commonMuiStackInputStyles
                }
            }
        }
    }
});
