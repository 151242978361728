import { ReactElement } from "react";
import { DeleteWithConfirmButton, SaveButton, Toolbar } from "react-admin";

interface EditToolbarProps {
    cloneButton?: ReactElement;
}

export const EditToolbar = ({ cloneButton }: EditToolbarProps) => {
    return (
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            <SaveButton size="medium" />

            {cloneButton}

            <DeleteWithConfirmButton
                size="medium"
                confirmTitle="Are you sure? This action is irreversible!"
                confirmContent=""
                confirmColor="warning"
            />
        </Toolbar>
    );
};
