import { ReferenceInput } from "react-admin";
import {
    DynamicWidthAutocompleteInputProps,
    DynamicWidthAutocompleteInput
} from "./DynamicWidthAutocompleteInput";

type DynamicWidthAutocompleteReferenceInputProps =
    DynamicWidthAutocompleteInputProps & {
        reference: string;
        source: string;
    };

export const DynamicWidthAutocompleteReferenceInput = ({
    source,
    optionText,
    reference,
    ...props
}: DynamicWidthAutocompleteReferenceInputProps) => {
    return (
        <ReferenceInput source={source} reference={reference}>
            <DynamicWidthAutocompleteInput optionText={optionText} {...props} />
        </ReferenceInput>
    );
};
