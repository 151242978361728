import {
    SignInRequestDTO,
    SignUpRequestDTO,
    SignUpResponseDTO,
    SignInResponseDTO,
    SignOutResponseDTO,
    ForgotPasswordRequestDTO,
    ResetPasswordRequestDTO,
    ForgotPasswordResponseDTO,
    ResetPasswordResponseDTO,
    CheckPasswordTokenResponseDTO,
    MeResponseDTO
} from "./auth.dto";
import { FetchHttpFactory } from "../../http";

const fetchHttpFactory = new FetchHttpFactory();
const http = fetchHttpFactory.createHttp("/admin/auth/");

export const signIn = (body: SignInRequestDTO) => {
    return http.post<SignInResponseDTO>("sign-in", body);
};

export const signUp = (body: SignUpRequestDTO) => {
    return http.post<SignUpResponseDTO>("sign-up", body);
};

export const signOut = () => {
    return http.post<SignOutResponseDTO>("sign-out", null);
};

export const forgotPassword = (body: ForgotPasswordRequestDTO) => {
    return http.post<ForgotPasswordResponseDTO>("forgot-password", body);
};

export const resetPassword = (body: ResetPasswordRequestDTO) => {
    return http.post<ResetPasswordResponseDTO>("reset-password", body);
};

export const checkPasswordToken = (token: string) => {
    return http.get<CheckPasswordTokenResponseDTO>(`reset-password/${token}`);
};

export const getMe = () => http.get<MeResponseDTO>("me");
