import { Edit, SimpleForm, required } from "react-admin";
import { PageTitle } from "components/navbar/PageTitle";
import { EditToolbar } from "components/toolbar/EditToolbar";
import { DefaultTextInput } from "components/default-text-input";
import { UploadInput } from "components/upload/UploadInput";

export const TagEdit = () => {
    return (
        <Edit
            title={
                <PageTitle
                    generatePageTitle={(record: any) => `${record?.key}`}
                />
            }
        >
            <SimpleForm toolbar={<EditToolbar />}>
                <DefaultTextInput
                    source="title"
                    type="text"
                    validate={[required()]}
                />

                <DefaultTextInput
                    source="color"
                    type="text"
                    validate={[required()]}
                />

                <UploadInput source="iconId" />
            </SimpleForm>
        </Edit>
    );
};
