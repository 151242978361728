import { DynamicWidthAutocompleteReferenceInput } from "components/dynamic-width-autocomplete-input";
import { ListActionsToolbarWithoutFilters } from "components/toolbar/ListActionsToolbarWithoutFilters";
import { orderStatuses } from "constants/order-statuses";
import { paymentStatuses } from "constants/payment-statuses";
import {
    AutocompleteInput,
    ChipField,
    Datagrid,
    DateField,
    List,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SelectInput
} from "react-admin";

const postFilters = [
    <DynamicWidthAutocompleteReferenceInput
        optionText="name"
        reference="users"
        source="buyerId"
        alwaysOn
    />,
    <DynamicWidthAutocompleteReferenceInput
        optionText="name"
        reference="users"
        source="sellerId"
        alwaysOn
    />,
    <DynamicWidthAutocompleteReferenceInput
        optionText="name"
        reference="users"
        source="collaboratorId"
        alwaysOn
    />,
    <SelectInput source="status" choices={orderStatuses} alwaysOn />,
    <SelectInput source="paymentStatus" choices={paymentStatuses} alwaysOn />
];

export const OrdersList = (props: any) => (
    <List
        {...props}
        sort={{ field: undefined }}
        actions={<ListActionsToolbarWithoutFilters />}
        filters={postFilters}
        perPage={50}
    >
        <Datagrid rowClick="edit" bulkActionButtons={false}>
            <ReferenceField source="buyerId" reference="users" sortable={false}>
                <ChipField source="name" />
            </ReferenceField>

            <ReferenceField
                source="sellerId"
                reference="users"
                sortable={false}
            >
                <ChipField source="name" />
            </ReferenceField>

            <ReferenceField
                source="pickUpId"
                reference="pick-ups"
                sortable={false}
            >
                <ChipField source="name" />
            </ReferenceField>

            <ChipField label="Order Status" source="status" sortable={false} />

            <NumberField
                label="Payment Amount"
                source="total"
                sortable={false}
            />

            <ChipField
                label="Payment Status"
                source="paymentStatus"
                sortable={false}
            />

            <DateField
                label="Date Created"
                source="createdAt"
                sortable={false}
                showTime
            />

            <DateField
                label="Date Paid"
                source="paidAt"
                sortable={false}
                showTime
            />

            <DateField
                label="Date Approved"
                source="approvedAt"
                sortable={false}
                showTime
            />

            <DateField
                label="Date Concluded"
                source="concludedAt"
                sortable={false}
                showTime
            />
        </Datagrid>
    </List>
);
